@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);

:root {
    --main-color: #00C805;
    --light-main-color: #f4fffc;
    --remove-color: #D82121;
    --base-gray: #ffffff;
    --off-gray: #fafafa;
    --item-gray: #9D9D9D;
    --text-gray: #7c7c7c;
    --trans: #ffffff00;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --titleFont: 2.2rem;
    --hoverBorder: rgb(231, 231, 231);
    --main-text: black;
    --frostedBorder: 1px solid rgb(0 0 0 / 7%);
    --frostedText: rgb(0 0 0 / 54%);
    --lightFrostedBackground: rgb(255 255 255 / 50%);
}

* {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif !important;

    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;


}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.remove-icon {
    color: var(--remove-color);
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}


body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--base-gray);
}

a {
    color: var(--main-color);
}

.divider {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.divider-inner {
    width: 90%;
    height: 1px;
    background-color: var(--frostedText);
    opacity: 0.2;
}



/*************************  AUTHENTICATION  *************************/

.login-container {

    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;


}

.login {
    background: #fcfcfc;
    border: var(--frostedBorder);
    backdrop-filter: blur(20px);
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1.3rem 2rem;
    box-sizing: border-box;
    width: 27rem;
}

.login-title {
    margin: 30px 0px;
}

.login-title>h1 {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 34px;
    font-weight: 800;
}

.login-title>p {
    width: 100%;
    text-align: center;
    color: var(--frostedText);
    font-size: 15px;
}

.login-form {
    width: 100%;
}

.login-form-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-form-input>label {
    color: black;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.login-form>form {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.login-form-input>input {
    background-color: var(--lightFrostedBackground);
    border: var(--frostedBorder);
    padding: 13px;
    border-radius: 6px;
    color: var(--frostedText);
    backdrop-filter: blur(20px);
    font-size: var(--px14);
}

.login-form-input>label>button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    outline: none;
}

.login-form-input>label>button:hover {
    text-decoration: underline;
}

.login-form-input>input:focus {
    background-color: var(--darkFrostedBackground);
    outline: none;
}

.login-form-input>input::placeholder {
    color: var(--frostedText);
}

.login-form>form button {
    width: 100%;
    background-color: var(--main-color);
    border: none;
    font-size: var(--px16);
    font-weight: 600;
    border-radius: 6px;
    padding: 14px;
    cursor: pointer;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    color: white;
    gap: 6px;
    align-items: center;
}

.login-form>form>p {
    text-align: center;
    color: var(--frostedText);
    font-size: var(--px13);
    margin: 0px 49px;
}

.login-back {}

.login-form>form>p>span {
    color: var(--main-color);
    cursor: pointer;
}

.login-form>form>p>span:hover {
    text-decoration: underline;
}

.login-footer {
    border-top: var(--frostedBorder);
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 17px;
}

.login-footer>p {
    color: var(--frostedText);
    font-size: var(--px15);
}

.login-footer>p>a,
.login-footer>button {
    background: none;
    border: none;
    color: var(--main-color);
    font-size: var(--px15);
    font-weight: 600;
    cursor: pointer;
}

.login-footer>p>a:hover {
    text-decoration: underline;
}

.verify {
    display: flex;
    justify-content: center;
}

.verify>button>i {
    color: white;
}


.form-nav {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}


.mantine-SegmentedControl-indicator {
    background-color: transparent;
    border-radius: 7px;
    border: solid 3px var(--main-color);
    margin-top: 4px;
    margin-left: 4px;
    box-shadow: none;

}

.mantine-SegmentedControl-label {
    font-weight: 600;
    font-size: 14px;
}

.mantine-SegmentedControl-root {
    padding: 0px;
    background-color: transparent;
    border: var(--frostedBorder);
    ;
    border-radius: 7px;
    padding-bottom: 0.1px;
}

.mantine-924wps {
    background-color: transparent;
}

.mantine-ym7ws5::before {
    background-color: transparent
}

.mantine-MultiSelect-input {
    background-color: red;
    color: white;
}

.mantine-Select-input {
    border: var(--frostedBorder);
    border-radius: 7px;
    height: 38px !important;
    font-weight: 600;
    background-color: transparent;
}

.mantine-kcpopt:focus,
.mantine-kcpopt:focus-within {
    outline: solid 2px var(--main-color);
}


.mantine-Select-item:hover {
    background-color: var(--light-main-color);
    ;
}

.mantine-Select-itemsWrapper>[data-selected="true"] {
    background-color: var(--main-color);
    color: white;
}



.mantine-Menu-dropdown {
    z-index: 150;
}









.home-container {
    display: grid;
    grid-template-columns: 350px 1fr;
}










.viewer-outlet {
    position: sticky;
    top: 0px;
    z-index: 0;
}











.global-more {
    padding: 5px 14px;
    border: none;
    display: flex;
    background-color: white;
    align-items: center;
}

.global-more>i {
    color: var(--text-gray);
    padding: 2px;
    border-radius: 50px;
    outline: var(--frostedBorder);
}


.side-navbar {
    background-color: var(--base-gray);
    height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-right: var(--frostedBorder);
    padding-left: 0px;
    padding-right: 0px;
    position: sticky;
    top: 0px;
    z-index: 15;
    overflow-y: scroll;
    justify-content: space-between;
    scroll-padding-top: 380px;
    left: 0px;

}

.side-navbar-sub {

    background-color: transparent;
    padding-bottom: 20px;


    z-index: 0;
}

.search-nav-btn {
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    ;
    outline: var(--frostedBorder);
    background-color: transparent;
}

.search-nav-btn:hover {
    color: var(--main-color);
}

.side-navbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.nav-btn>span {
    display: flex;
    width: 100%;
    gap: 10px;

    align-items: center
}

.nav-btn {
    cursor: pointer;
}

.activity-button {
    cursor: pointer;
}

.nav-btn>span>p {
    color: var(--text-gray);
    font-size: 11px;
    font-weight: normal;
    outline: var(--frostedBorder);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 9px;
    border-radius: 15px;
    display: none;


}


.mantine-ecft5f {
    padding: 7px 35px;
}

.mantine-Accordion-label {
    font-weight: 600;
}

.mantine-731ldn {
    padding-bottom: 0px;
}



.side-navbar-sub>div {
    padding: 0px 23px;
}

.side-navbar-sub>h1 {
    font-weight: 800;
    font-size: 37px;
    padding: 25px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 70px;
}

.profile-avatar {
    width: 37px;
    border-radius: 100px;
    cursor: pointer;
    margin: 25px;
}

.account-strip {
    width: fit-content;
    display: flex;
    align-items: center;
    height: 40px;
    height: 70px;
    color: var(--text-gray);
    gap: 16px;
    cursor: pointer;


}

.account-strip>i {
    color: var(--text-gray);
    padding: 8px;
    outline: var(--frostedBorder);
    border-radius: 50px;
}

.account-strip>span {
    color: var(--text-gray);
    font-size: var(--px13);
}

.side-nav-btns {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.nav-page {
    position: sticky;
    top: 0px;
    ;
}

.search-btn {
    width: 100%;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    outline: var(--frostedBorder);
    background-color: transparent;
    padding: 12px 14px;
    border-radius: 10px;
    gap: 10px;
    cursor: text;
}


.search-btn>i {
    color: var(--text-gray);
}

.search-btn>span {
    color: var(--text-gray);
    font-size: var(--px15);
}

.nav-btn-items {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.horizontal-navs {
    display: flex;
    flex-direction: row;
    padding-top: 45px;
    margin-left: 367px !important;
    gap: 11px;
}

.horizontal-nav-sub {
    height: fit-content;
}

.horizontal-navbar {
    height: fit-content;
    position: fixed;
    ;
    width: 100%;
}

.hori-nav-btn {
    padding: 8px 11px !important;
    border-radius: 50px !important;
}

.hori-nav-btn>span {
    font-size: 14px;
}

.nav-btn {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    padding: 12px 14px;
    border: none;
    align-items: center;
    gap: 10px;
    font-size: var(--px15);
    font-weight: 600;
    color: var(--text-gray);
}


.nav-btn>span {
    color: var(--text-gray);
}

.nav-btn>i {
    color: var(--text-gray);
}

.nav-btn:hover {

    border-radius: 10px;

}

.colored-icon {
    color: var(--main-color);
}

.focused-nav>button {
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    color: black;
    outline: var(--frostedBorder);

}

.focused-nav>label {
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    color: black;
    outline: var(--frostedBorder);
}

.focused-nav>label>i {
    color: var(--main-color);
}

.focused-nav>button:hover {
    background-color: white;
}

.focused-nav>button>span {
    color: black;
}

.focused-nav>button>i {
    color: var(--main-color);
}










.mantine-np8w2m {
    padding: 0px;
    background-color: transparent;
    border: var(--frostedBorder);
}













.mini-rack-items {
    display: flex;
    flex-direction: column;
    border: var(--frostedBorder);
    border-radius: 10px;
}


.mini-rack-item {
    display: grid;
    grid-template-columns: 1fr 55px;
    align-items: center;
    border-bottom: var(--frostedBorder);
}

.mini-rack>h3 {
    font-size: var(--px15);
    font-weight: 600;
    color: var(--text-gray);
}

.mini-rack {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.rack-left {
    display: flex;
    align-items: center;
    padding: 17px 0px;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.rack-left>h3 {
    font-size: var(--px17);
    font-weight: 700;
    padding: 0px 19px;
    color: var(--main-color);
}

.rack-text {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.rack-text>h4 {
    font-size: var(--px15);
    font-weight: 600;
    color: var(--text-gray);
}

.rack-text>p {
    font-size: var(--px12);
    font-weight: 500;
    color: var(--text-gray);
}

.rack-right>button {
    padding: 26px 14px;
    border: none;
    display: flex;
    background-color: white;
    align-items: center;
}

.rack-right>button>i {
    color: var(--text-gray);
    padding: 2px;
    border-radius: 50px;
}

.rack-right>button>i:hover {
    color: var(--main-color);
}

.mantine-1nbod7e {
    border-radius: 10px;
}

.mini-rack-item:last-of-type {
    border-bottom: none;
}

.mantine-qduq72[data-hovered] {
    background-color: var(--main-color);
}

.mantine-qduq72[data-hovered]:hover {
    background-color: var(--main-color);
}

.rack-right>[aria-expanded="true"]>i {
    background-color: var(--main-color);
    color: white;
}

.rack-right>[aria-expanded="true"]>i:hover {
    background-color: var(--main-color) !important;
    color: white;
}

.mantine-kcpopt:focus,
.mantine-kcpopt:focus-within {
    border-color: var(--main-color);
    border-width: 1px;
}













.activity-header {
    padding: 8px 63px;
    display: flex;
    flex-direction: column;
    gap: 47px;
    background-color: var(--base-gray);
    position: relative;
    z-index: 15;
}


.activity-segment {
    width: 220px;
}

.activity-select {
    width: 220px;
}

.activity-sort {
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
}


.activity-bar {
    display: flex;
    justify-content: space-between;
}



.left-activity {
    width: 120px;
}

.activity-status>h3 {
    font-size: var(--px12);
    font-weight: normal;
    color: var(--text-gray);
    display: flex;
    gap: 4px;
}

.activity-status>h3>button {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-weight: 600;
    font-size: var(--px13);
    cursor: pointer;

}

.activity-icon-btn {
    width: 120px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.activity-status>h3>button:hover {
    text-decoration: underline;
}

.activity-icon-btn>button>i {
    color: var(--text-gray);
}

.activity-icon-btn>button {
    background-color: transparent;
    border: none;
    color: var(--text-gray);
    cursor: pointer;
    font-size: var(--px15);
    font-weight: 600;
    outline: none;
}

.activity-title>h4>span {
    color: rgb(22, 22, 22);
}

.activity-title {
    display: flex;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    justify-content: center;
}

.activity-title>h4 {
    font-size: var(--px16);
    font-weight: 600;
    color: var(--main-color);
}

.activity-title>h1 {
    font-weight: 700;
    font-size: 37px;
    margin-top: -10px;
    margin-bottom: -6px;
}

.activity-title>p {
    font-size: var(--px13);
    font-weight: 500;
    color: var(--text-gray);
}

.activity-title>span {
    font-weight: 600;
    font-size: 15px;
    color: var(--text-gray);
}



















.explore-page {
    display: grid;
    grid-template-rows: 260px 1fr;
    ;
    height: 100vh;
}


.modal-table {
    margin: 0px !important
}




.table-container {
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    white-space: nowrap
}

.table-header {
    background-color: var(--base-gray);
    position: sticky;
    top: 0px;
    z-index: 8;
    min-width: -moz-fit-content;
    width: 100%;
    min-width: fit-content;
    padding-top: 7px;
}



.table-header>div>span {
    font-weight: 600;
    padding-top: 0px !important;
}

.table-body {
    width: fit-content;
}

.table-column-action {
    border: none;
    background-color: transparent;
    width: fit-content;
}

.table-row {
    display: grid;
    grid-template-columns: 70px 110px 150px 110px 72px 110px 110px 140px 140px 140px 110px 110px 110px 100px 130px;
    gap: 40px;
    border-bottom: var(--frostedBorder);
    align-items: center;
}




.table-row>span>p {
    cursor: pointer;
}

.table-row>span>p:hover {
    opacity: 0.65;
}

.table-header>.table-row {
    align-items: flex-start;
}

.table-header>.table-row>span {
    cursor: pointer;
}

.header-action-btn>button {

    border: none;
    background-color: transparent;
    padding: 2px;
    border-radius: 50px;
    outline: var(--frostedBorder);
    margin-left: 15px;
    width: 28px;
    height: 28px;
    margin-top: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-action-btn>button>i {
    color: var(--text-gray);
}

.header-action-btn>button>i:hover {
    color: gray;
}

.header-action-btn {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    padding: 0px 14px;
    ;
}

.table-row>span {
    font-size: var(--px14);
    padding: 30px 0px;
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: normal;

}

.table-row>span>div {
    font-size: 11px;
    font-weight: normal;
    color: var(--text-gray);
    transition: 0.2s ease;

}

.table-row>span>div:hover {
    color: var(--main-color);
    font-weight: 600;
    cursor: pointer;
}

.table-icon>button>i {
    outline: var(--frostedBorder);
}

.table-row>.table-icon {
    position: sticky;
    left: 0px;
    z-index: 1;
    background-color: transparent;
    backdrop-filter: blur(15px);
    margin-right: 15px;
    border-radius: 10px;
}

.table-icon {
    display: flex;
    justify-content: center;
}

.table-row-accordion {
    display: flex;
    align-items: center;
    border-bottom: var(--frostedBorder);
}

.table-row-accordion>.rack-right {
    margin-right: -60px;
    position: sticky;
    z-index: 1;
    align-self: baseline;
    left: 0px;
}

.tracking-accordion {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 950px;


    padding: 25px;
    position: sticky;
    left: 10px;
    gap: 25px;

}

.tracking-accordion-wrapper {
    margin: 0px 30px;
    margin-bottom: 40px;
    z-index: 2;
    ;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    outline: var(--frostedBorder);
    border-radius: 10px;
    position: sticky;
    left: 40px
}

.accord-chip>h4 {
    font-size: var(--px14);
    font-weight: normal;
}

.accord-chip>p {
    font-size: var(--px13);
    font-weight: 600;
}

.mantine-Accordion-item>[aria-expanded="true"]>span>.table-row {
    border-color: transparent;
}







.resource-btn {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.resource-btn>i {
    color: var(--text-gray);
    font-size: 20px;
    transition: 0.2s ease;
}

.resource-btn>i:hover {
    color: var(--main-color);
}

.activity-query {
    border: var(--frostedBorder);
    display: grid;
    grid-template-columns: 1fr 50px;
    transition: 0.3s ease;
    outline: solid 3px transparent;
    border-radius: 7px;
    padding-left: 5px;
    width: 220px;
    justify-content: space-between;
    outline: solid 3px transparent;

}

.activity-query>input {
    border: none;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    outline: none;
    font-size: var(--px14);
    font-weight: normal;
    color: black;


}

.activity-query>input::placeholder {
    color: var(--text-gray);
}

.smart-query-open {
    width: 100%;
    outline: solid 3px var(--main-color);
    border-radius: 50px !important;
    grid-template-columns: 1fr 150px;
}

.smart-query-open>button {
    position: absolute;
    right: 0px;
    margin-top: 10px;
}

.mantine-Tooltip-tooltip {
    background-color: transparent;
    color: var(--text-gray);

    font-size: 12px;
}

.mantine-Drawer-content {
    background-color: var(--base-gray);
    border-left: var(--frostedBorder);
    border-bottom: var(--frostedBorder);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.query-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: var(--main-color);

    border-radius: 50px;
    border: solid 3px var(--main-color);
}

.query-close>i {
    color: white;
    font-size: 20px;
}


.query-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;

}

.query-btn>button>i {
    color: var(--main-color);
    font-size: 16px;
    margin-left: -2px;
}

.query-btn>button {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-size: var(--px14);
    font-weight: 600;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    border: var(--frostedBorder);
    height: 20px;
    padding: 11px;

}

.query-btn>button:hover {
    background-color: var(--light-main-color);
}

.query-btn>button:first-of-type {
    border-radius: 15px 0px 0px 15px;
}

.query-btn>button:last-of-type {
    border-radius: 0px 15px 15px 0px;
    border-left: none;
}











.stock-results {
    display: flex;
    flex-direction: column;
    gap: 15px;
    display: none;

}

.stock-results>button {
    background-color: transparent;
    border: none;
    display: flex;

    gap: 1px;
    font-size: var(--px15);
    font-weight: 600;
    color: var(--text-gray);
    cursor: pointer;
    outline: none;
    flex-direction: column;
    border-bottom: var(--frostedBorder);
    padding-bottom: 15px;
}

.stock-results>button:last-of-type {
    border-bottom: none;
}

.stock-results>button>span {
    color: var(--text-gray);
    font-size: var(--px14);
    font-weight: normal;
}











/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;

}

/* Track */
::-webkit-scrollbar-track {
    background: var(--base-gray);
    margin-top: 60px;

}


/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.09);
    border-radius: 50px;
    width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}




















.empty-strip {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: var(--base-gray);
    height: 100vh;
}

.empty-strip-text>img {
    width: 100px;
}

.empty-strip-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-strip-text>h1 {
    font-size: 16px;
}

.empty-strip-text>p {
    font-size: 14px;
    color: var(--text-gray);
}

.empty-strip-text>p>span {
    color: var(--main-color);
    font-weight: 600;
}















.action-form {
    width: 460px;
    padding: 0px 20px;
}

.action-form-input {
    display: flex;
    ;
    justify-content: space-between;
}

.action-form-input>input {
    border: var(--frostedBorder);
    padding-left: 15px;
    border-radius: 7px;
    height: 45px;
    font-size: var(--px14);
}

.action-form-input>input::placeholder {
    color: var(--text-gray);
}

.action-form-input>input:focus {
    outline: solid 3px var(--main-color);
}

.action-input-text>label {
    font-size: var(--px15);
    font-weight: 600;
}

.action-input-text>p {
    font-size: var(--px13);
}

.modal-live-text {
    font-size: var(--px13);
}

.action-form-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 0px;
}

.mantine-19pz3dh {
    padding-bottom: 0px;
}

.action-form>h1 {
    padding: 40px 0px;
    font-weight: 800;
    font-size: 37px;
}

.action-form>h1>p {
    font-size: 16px;
    font-weight: 700;
    color: var(--main-color);
    margin-bottom: -5px;
    text-transform: uppercase;
}

.submit-min {
    display: flex;
    justify-content: space-between;
    border-top: var(--frostedBorder);
    padding-top: 20px;
    margin-top: 30px;
    padding-bottom: 20px;
}


.submit-min>h3 {
    font-size: var(--px15);
}

.submit-min>p {
    font-size: var(--px14);
    font-weight: 600;
}

.action-form>p {
    font-size: 14px;
    text-align: center;
}

.action-form-submit {
    padding-bottom: 15px;
}

.text-floater {
    font-size: 13px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    color: var(--text-gray);
}

.text-floater>span {
    font-weight: 600;
}

.lg-btn {
    width: 100%;
    padding: 15px;
    background-color: var(--main-color);
    border: none;
    border-radius: 7px;
    color: white;
    font-size: var(--px16);
    font-weight: 600;
    cursor: pointer;
    outline: none;
}

.loading-ind {
    width: 500px;
    margin-top: 10px;
}

.indeterminate-progress-bar {
    /* Color */
    background-color: var(--base-gray);

    /* Rounded border */
    border-radius: 9999px;

    /* Size */
    height: 0.3rem;

    position: relative;
    overflow: hidden;
    margin-top: 5px;
    width: 100%;
}

.indeterminate-progress-bar__progress {
    /* Color */
    background-color: var(--main-color);

    /* Rounded border */
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    width: 50%;

    /* Move the bar infinitely */
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
    box-shadow: var(--lightShadow);
}

@keyframes indeterminate-progress-bar {
    from {
        left: -50%;
    }

    to {
        left: 100%;
    }
}

.load-subtext {
    width: 100%;
    text-align: center;
    color: var(--text-gray);
    margin-top: 3px;
}

.widget-load-container {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}







.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    border-bottom: var(--frostedBorder);
    width: 100%;
    padding-top: 0px;
}

.mantine-1k9itrp {
    width: 100%;
}

.modal-header>button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.modal-header>button>i {
    color: black;
    font-size: 25px;
}

.modal-header>h3 {
    font-size: var(--px15);
    font-weight: 600;
    color: black;
}

.modal-header>h3>span {
    color: var(--main-color);
    font-weight: 600;
}

.red-title {
    color: var(--remove-color) !important;
}

.green-title {
    color: var(--main-color) !important;
}


















.pending-item {
    color: var(--text-gray) !important;
}

.no-orders {
    font-size: var(--px14);
    padding: 20px 10px;
}

.order-tracking-dock {
    border-top: var(--frostedBorder);
}

.order-tracking-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--frostedBorder);
    padding: 16px 20px;
}

.order-tracking-item-left>h3 {
    font-size: var(--px13);
    font-weight: normal;
}

.order-tracking {
    border-bottom: var(--frostedBorder);
}

.mantine-731ldn {
    border-bottom: var(--frostedBorder);
    padding-top: 0px;
}

.order-tracking-item:last-of-type {
    border-bottom: none;
}

.order-tracking-item-left>p {
    font-weight: 600;
    font-size: var(--px14);
    margin-top: -2px;
}

.order-tracking-item-right>h4 {
    font-weight: 700;
    font-size: var(--px14);
    color: var(--main-color);
}

.order-tracking-item-right>p {
    font-size: var(--px14);
    color: var(--text-gray);
    margin-top: -3px;
    text-align: right;
}




.darkmode-toggle {
    background-color: transparent;
    border: none;
    position: absolute;
    margin: 0px 17px;
    padding: 0px 5px;
    opacity: 0.4;
    top: 50px;
}





















.native-control {
    cursor: pointer;
}

.native-control>label {
    display: flex;
    outline: var(--frostedBorder);
    font-size: 14px;
    font-weight: normal;
    padding: 5.5px 14px;
    justify-content: center;
    cursor: pointer;


    transition: 0.2s ease-in;
}

.native-control:last-of-type>label {
    border-radius: 0px 5px 5px 0px;
}

.native-control:first-of-type>label {
    border-radius: 5px 0px 0px 5px;
}

.native-control>input:checked+label {
    border-color: transparent;
    outline: solid 3px var(--main-color);
    border-radius: 5px;
    font-weight: 600;
}


.native-control>input {
    display: none;
}

.activity-segment {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.mantine-1oy32v8[data-active] {
    background-color: var(--base-gray);
}























.learn-blocks {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.learn-blocks>h1 {
    font-weight: 800;
    font-size: 33px;
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0px;
}

.learn-block>h2 {
    font-size: 16px;
    font-weight: 600;
}

.learn-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 32px;
}

.learn-block>p {
    font-size: var(--px14);
    font-weight: normal;
    color: black;
}

.mantine-1227ywg>thead>tr>th {
    font-weight: 600;
    color: var(--main-color);
}

.learn-block>h3 {
    font-weight: 600;
    font-size: 15px;
}

.learn-block>ul {
    font-size: 14px;
    list-style-type: none;
}

.learn-block>ul>li {
    font-size: var(--px14);
    font-weight: normal;
    color: black
}

.mantine-1227ywg>caption {
    color: var(--text-gray);
    font-size: var(--px13);
    margin-bottom: 10px;
    padding: 3px;
}

.learn-block>ul>li>b {
    color: var(--main-color);
}

.mantine-1227ywg>tbody>tr>td {
    border-top: var(--frostedBorder);
}

.mantine-1227ywg>thead>tr>th {
    border-bottom: var(--frostedBorder);
}

thead {
    position: sticky;
    top: -20px;
    background-color: var(--base-gray);
}

.mantine-Select-input {
    font-weight: normal;
}

.mantine-Select-input::placeholder {
    color: var(--text-gray);
}

.mantine-1s2yyo5[data-active] {
    background-color: transparent;
}






















.stock-bar-container {
    width: 100%;
    position: sticky;
    bottom: 0px;
    top: 125px;
    bottom: 20px;
    z-index: 1;
}


/* .stock-bar-container:first-child {
    background-color: red;
} */

.stock-bar-line {
    width: 100%;
    background: var(--main-color);
    height: 3px;
}


.stock-bar {
    width: calc(100vw - 315px);
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: sticky;
    left: 0;
    height: 0px;
    top: 0;
}

.stock-pill {
    width: -moz-fit-content;
    width: fit-content;
    background-color: var(--main-color);
    padding: 8px 14px;
    border-radius: 50px;
    border: none;
    transition: 0.3s ease;
    transition-delay: 100ms;
    position: absolute;
    bottom: -15px;
}

.stock-pill:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.stock-pill>h3 {
    font-size: var(--px14);
    font-weight: 600;
    color: white;
}

.table-row-stock:first-of-type {
    border: solid 4px red;
}





























.wheel-modal-title {
    width: 700px;
    margin-top: -25px;
}


.table-active-row {


    transition: 0.2s ease;
    outline-color: transparent;
}

/* .table-active-row:hover {

    z-index: 10;
    background-color: var(--off-gray);
} */

.table-active-row>* {
    cursor: pointer;
}

.button-row-item {
    cursor: pointer;
    color: var(--main-color);
    font-weight: 600;
}

.mantine-gszoqu:focus,
.mantine-gszoqu:focus-within {
    border-color: var(--main-color);
    border-width: 3px;
}

.mantine-DateInput-pickerControl {
    background-color: red;
}

.wheel-row {
    grid-template-columns: 70px 148px 97px 119px 119px 114px 128px 146px 65px !important
}
















.rack-feed-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.my-masonry-grid {
    margin: 90px 0px;
}


.my-masonry-grid {
    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;

    width: auto;
    gap: 40px;
    padding: 5px 45px;
    max-width: 1300px;
}

.my-masonry-grid_column {


    background-clip: padding-box;
}


.my-masonry-grid_column>img {
    background: grey;
    margin-bottom: 30px;
}


.rack-card {
    outline: var(--frostedBorder);
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 35px;
    height: 525px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}

.rack-left-item {
    display: flex;
    align-items: center;
    gap: 20px;
}



.rack-card-header {
    display: flex;
    justify-content: space-between;
}

.rack-ambient-inner {
    width: 80%;
    height: 4px;
    border-radius: 50px;
}


.ambient-red {
    background-color: var(--remove-color);
}

.ambient-green {
    background-color: var(--main-color);
}

.status-red {
    color: var(--remove-color);
}

.status-green {
    color: var(--main-color);
}

.action-menu {
    position: fixed;
    right: 40px;
    top: 50px;
    z-index: 150;
}

.action-menu>p {
    font-size: 14px;
    color: var(--text-gray);
}


.rack-ambient-light-strip {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -23px;
}

.rack-header-left>h2 {
    font-size: 25px;
    font-weight: 700;
}

.rack-header-left>p {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-gray);
    margin-top: -5px;
}

.rack-header-left>span {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -9px;
    color: var(--text-gray);
}

.rack-header-left {
    display: flex;
    flex-direction: column;
    ;
}

.rack-header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.rack-header-right>span {
    font-size: 13px;
    font-weight: 600;
    color: var(--text-gray);
}

.rack-header-right>h4 {
    font-size: 16px;
    font-weight: 600;
}



.rack-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rack-item-header-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.rack-item-header-left>span {
    font-size: 12px;
    color: var(--text-gray);
}

.rack-item-header-left>h3 {
    font-size: 15px;
    font-weight: 600;
    margin-top: -1px;
    margin-bottom: -1px;

}

.rack-item-header-left>p {
    font-size: 12px;
    color: var(--text-gray);
}

.rack-card-content {}

.rack-item-block {
    display: flex;
    justify-content: space-between;
}

.rack-blockling {
    color: var(--text-gray);
}

.rack-blockling>span {
    font-size: 12px;
    display: flex;
    gap: 4px;
    margin-bottom: -2px;
}

.rack-blockling>p {
    font-size: 11px;
}

.rack-feed-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: transparent;

    border-radius: 10px;
    color: black;
    outline: var(--frostedBorder);
    padding: 15px;
}

.rack-item-block-divider {
    width: 90%;
    height: 1px;
    border-top: var(--frostedBorder);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.view-more-btn {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--text-gray);
    border: none;
    gap: 2px;
}

.rack-item-footer {
    display: flex;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: -5px;
}

.global-more {
    background-color: transparent;
}







.rack-card-immersive {
    border: none;
    outline: none;
    box-shadow: none;
}

.rack-card-immersive>.rack-ambient-light-strip>div {
    border-radius: 0px;
}









.slider-menu-container>input {
    width: 100%;
}

.slider-menu-container {
    margin: auto;
    width: 90%;
    padding: 10px 0px;
}























.notif-feed {
    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 2px;
    margin-bottom: 40px;
}

.n-feed {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.alert-container {
    display: grid;
    grid-template-columns: 435px 1px 1fr;
}

.n-feed-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    outline: var(--frostedBorder);
    align-items: center;
    border-radius: 9px;
}

.n-avatar {
    width: 37px;
    border-radius: 50px;
}

.n-feed-item-left {
    display: flex;
    align-items: center;
    gap: 15px;
}

.n-feed-item-details>h3 {
    font-size: var(--px14);
    font-weight: 600;
    height: fit-content;
}

.n-feed-item-details>p {
    font-size: var(--px13);
    color: var(--text-gray);
}

.n-feed-item-right>.rack-right>button {
    padding: 0px;
}

.notif-feed-chunk {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.notif-feed-chunk>h4 {
    font-weight: 500;
    color: black;
    font-size: 15px;
    color: var(--text-gray);
}

.notif-container {
    padding: 0px 35px;
    display: flex;
    gap: 25px;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
}

.notif-container::-webkit-scrollbar-track {
    margin-top: 190px;
}

.alert-strip {
    display: flex;
    justify-content: space-between;
    padding-top: 75px;
    align-items: center;
    position: sticky;
    top: 0px;
    background-color: var(--base-gray);
}

.alert-actions>button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-actions>button {
    background-color: transparent;
    width: 35px;
    height: 35px;
    outline: var(--frostedBorder);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    gap: 7px;
    color: var(--text-gray);
}

.alert-actions>button>span {
    font-weight: 600;
    font-size: 14px;
}




.alert-vert-divider {
    height: 100vh;
    width: 1px;
    background-color: #43434312;
}

.status-dot {
    width: 9px;
    height: 9px;
    background-color: var(--main-color);
    border-radius: 50px;
}

.mantine-ym7ws5 {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) !important;
    outline: var(--frostedBorder);
    border-radius: 9px;
}

.mantine-b47ys6 {
    font-weight: 600;
    font-size: var(--px14);
}

.mantine-8c9alo {
    color: var(--text-gray);
    font-size: var(--px13);
}

.mantine-Notification-icon>i {
    color: white;
    padding: 8px;
    border-radius: 50px;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;

}

.mantine-ym7ws5[data-with-icon] {

    padding: 11px;
    padding-left: 20px;

}

.mantine-14zcsvh {
    width: 360px;
}

.mantine-ym7ws5:not(:first-of-type) {
    margin-top: 18px;
}

.n-avatar-compact {
    height: 37px;
    border-radius: 50px;
}























.widget {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    outline: var(--frostedBorder);
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    scroll-snap-align: start;

}

.widget-panel-container {
    padding: 0px 23px;
}

.widget-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.widget-title-left {
    display: flex;

    gap: 7px;
    align-items: center;

}

.widget-title-left>p {
    font-size: 13px;
}

.widget-title-left>i {
    border-radius: 50px;
    width: fit-content;
    height: 35px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    color: rgb(30, 30, 248);
}

.widget-title-left>h3 {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 175px;
}

.widget-title-right>button>i {
    color: var(--text-gray);
    font-size: 20px;
}

.widget-title-right {
    display: flex;
    gap: 5px;
}

.widget-title-right>button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
}

.widget-list {
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-top: 1px;

}

.widget-panel {
    margin-bottom: 103px;
}

.widget-list-item {
    display: flex;
    justify-content: space-between;
}

.widget-list-item-left>h3 {
    font-size: 15px;
    font-weight: 600;

}

.widget-list-item-left>p {
    font-size: 14px;
    color: var(--text-gray);
}

.widget-list-item-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.widget-list-item-right>p {
    font-size: 14px;
    color: var(--text-gray);
}

.widget-list-item-right>h4 {
    font-size: 15px;
    font-weight: 600;
    color: var(--main-color);
}



.widget-list-item-column {
    display: flex;
    flex-direction: column;
}

.widget-list-item-column-top {
    display: flex;
    align-items: center;
}

.widget-list-item-column-top>h3 {
    font-size: 15px;
    font-weight: 600;
}

.widget-list-item-column-top>h3>p {
    display: inline;
    font-size: 14px;
    color: var(--text-gray);
}

.widget-list-item-column-bottom {
    display: flex;
}

.widget-list-item-column-bottom>p {
    font-size: 13px;
    color: var(--text-gray);
}

.widget-list-item-column-top>h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--main-color);
}

.widget-dots {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.widget-dot {
    width: 5px;
    height: 5px;
    background-color: var(--base-gray);
    filter: invert();
}

.add-widget {
    width: fit-content;
    height: 50px;
    background-color: transparent;

    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    ;
    color: var(--text-gray);
    font-size: var(--px15);
}

.widget-divider {
    width: 100%;
    height: 1px;
    border-top: var(--frostedBorder);
}

.add-widget>i {
    color: var(--text-gray);
}

.widget-wrapper {
    display: grid;
    grid-template-columns: 100% 20px;
}

.widget-updown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.2s ease;
    filter: brightness(0.3);
}

.widget-updown:hover {
    transform: scale(1.3);
    filter: brightness(0.6);
}




.widget-updown>button {
    background-color: transparent;
    border: none;
    height: 15px;
    filter: opacity(0.3);


}

.widget-updown>button:hover {
    filter: opacity(1);
}

.widget-updown>button>i {
    font-size: 17px;
    transition: 0.2s ease;
}

.widget-updown>button:hover>i {
    transform: scale(1.3);
}


.widget-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    width: 347px;
    left: 0px;
    backdrop-filter: blur(15px);


    padding: 0px 25px;
}

.widget-action-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: var(--frostedBorder);
    padding: 10px 13px;
    align-items: center;
    font-size: 14px;
    border-radius: 9px;
    margin-bottom: -3px;
}

.lg-widget-title {
    display: flex;
    ;
    gap: 0px;
    flex-direction: column;
    align-items: center;
}

.lg-widget-title>span {
    margin-bottom: -7px;
    font-size: 14px;
}

.lg-widget-title>h1 {
    font-size: 32px;
}

.place-chunk {
    width: 32px;
    height: 32px;
}

.large-widget-header {
    font-size: 13px;
    font-weight: 600;
    ;
}

.large-widget-header>p {
    text-align: center;
    margin-bottom: 7px;
    font-weight: normal;
}

.mantine-1rnkhfs {
    color: black
}

.widget-setup-btn {
    outline: var(--frostedBorder);
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    font-size: 15px;
    font-weight: 600;
    gap: 6px;
    padding: 0px 15px;
    border-radius: 8px;


}

.widget-setup-btn-wrapper {
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    padding-top: 7px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.widget-setup-btn-wrapper>p {
    font-size: 12px;
    color: var(--text-gray);
}







.ltr-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ltr-widget>span {
    font-size: 15px;
    font-weight: 600;

}

.ltr-widget>div>span {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: -10px;
    color: var(--text-gray);
}

.ltr-widget>div>h1 {
    font-size: 20px;
    ;
}

.ltr-widget>div>p {
    font-size: 11px;
    color: var(--text-gray);
    width: 135px;
}








.status-dot {
    width: 10px;
    height: 10px;

    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: black;
}


































.modal-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.575);
    z-index: 190;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    backdrop-filter: blur(9px);
}

.modal-wrapper {
    width: 900px;
    height: fit-content;
    background-color: var(--base-gray);
    margin-top: 15vh;
    border-radius: 18px 18px 0px 0px;
    padding: 45px 55px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


.modal-content>h2 {
    color: white;
}

.modal-content>h1 {
    color: white;
}

.modal-content>p {
    color: white;
}

.modal-navbar>lord-icon {
    width: 435px;
    height: 435px;
    opacity: 0.2;
    margin-right: -65px;
    margin-top: -51px;
}

.modal-navbar {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 40px;
    z-index: 50;
}

.modal-close {
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    outline: var(--frostedBorder);
}

.modal-close>i {
    font-size: 25px;
    color: black
}

.modal-title {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: -305px;
}

.modal-title>h2 {
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: -5px;
}

.modal-title>h1 {
    color: white;
    font-size: 40px;
    line-height: 60px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    position: relative;
    z-index: 1;
}

.modal-content>p {
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 47px;
    width: 90%;
}

.modal-hero-title {
    font-size: 35px;
    font-weight: 700;
}

.modal-hero-group {
    display: flex;
    justify-content: space-between;
    width: 94%;
    height: fit-content;
    align-items: center;
    margin-top: -5px;
}

.manage-table-row {
    grid-template-columns: 45px 100px 191px 80px 76px 140px !important
}




















.vert-nav-btn {
    padding: 8px 11px !important;
}

.split-modal {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
}

.modal-side-nav>.nav-btn-items {
    margin-top: 40px;
    gap: 8px
}

.settings-page {
    margin-top: 32px;
}

.settings-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-gray);
    display: flex;
    justify-content: space-between;
}

.settings-item {
    font-size: 14px;
    accent-color: var(--main-color)
}

.check-bind {
    display: flex !important;
    gap: 7px;
    font-weight: 500;
    color: var(--text-gray);
    cursor: pointer;
}

.check-bind>label {
    cursor: pointer;
}

.setting-chunk {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding-bottom: 25px;
}

.settings-item {
    display: flex;
    flex-direction: column;
    gap: 7px;
    opacity: .6;
}

.settings-item:has(.check-bind>input:checked){
    opacity: 1 !important;
}


.settings-item > div{
    display: none;
}

.settings-item:has(.check-bind>input:checked){
    opacity: 1 !important;
}

.settings-item:has(.check-bind>input:checked) > div{
    display: flex !important;
}

.check-bind>input:checked+label {
    font-weight: 600;
}

.minimal-btn {}

.settings-item .action-form-input>input {
    height: 37px;
}

.minimal-btn {
    color: var(--main-color);
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    align-items: center;
}

.minimal-btn>i {
    font-size: 10px;
    color: var(--main-color);
}

.changes-btn {
    color: var(--main-color);
}


.settings-mirror-extra-options{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-top: 10px;
    gap: 10px;
}


.settings-mirror-extra-options > h4{
    font-weight: 600;
}


.mantine-Checkbox-input:checked {
    background-color: var(--main-color);
    border-color: var(--main-color);

}













.modal-hero-actions {
    display: flex;
    ;
}

.modal-hero-actions>button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-gray);
    padding: 0px 15px;
    border-radius: 8px;
    height: 38px;
}

.modal-hero-actions>button>i {
    font-size: 25px;
    color: var(--main-color);
}

.modal-hero-subtitle {
    font-size: 13px;
    font-weight: 500;
    color: var(--text-gray);
    text-align: left;
}

.user-page {
    display: grid;
    height: 100vh;
    height: 100vh;
    grid-template-rows: 44px 1fr;
    gap: 36px;


}


.action-form-checks {
    display: flex;
    gap: 12px;
}

.action-form-checks>div {
    display: flex;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-gray);
    cursor: pointer;
}

.action-form-checks>div>label {
    color: var(--text-gray);
}














.performance-container {
    display: grid;
    grid-template-columns: 435px 1px 1fr;
}

.mantine-gszoqu {
    border-radius: 5px;
    border: none;
    padding: 14.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: var(--frostedBorder);
}

.mantine-7yisjr[data-selected] {
    background-color: var(--main-color) !important;
}

.mantine-7yisjr[data-selected]:hover {
    background-color: var(--main-color);
}

.mantine-7yisjr[data-in-range] {
    background-color: var(--light-main-color);
}

.mantine-1bpldn3 {
    font-size: 13px;
    min-height: 0px;
    height: 33.5px;
    border: var(--frostedBorder);
    border-radius: 5px;
    min-width: 220px;
}

.mantine-1bpldn3:focus {
    outline: solid 3.5px var(--main-color);
    border: none !important;
}

.mantine-1e6hn20 {
    font-size: 14px;
    min-height: 0px;
    height: 33.5px;
    border: var(--frostedBorder);
    border-radius: 5px;
    padding-left: 20px;
    color: black;
    padding-right: 20px;
    width: 220px;
}

.mantine-DatePickerInput-placeholder {
    color: black;
}

.date-picker-icon {
    font-size: 14px;
    color: var(--text-gray);
}

.report-feed {
    margin: 110px 40px;
}

.report-item {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    outline: var(--frostedBorder);
    border-radius: 11px;
    padding: 30px 30px;
    text-align: center;
    margin-bottom: 20px;
}

.report-item-text>h3 {
    font-size: 30px;
    color: var(--main-color);
    line-height: 30px;
}

.report-item-text>p {
    font-size: 12px;
    color: var(--text-gray);
}

.report-item-text>span {
    font-size: 14px;
    font-weight: 600;
}
























.widget-canvas {
    display: flex;
    flex-direction: column;
    border-radius: .6rem;
    padding: .35rem;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    z-index: 1250;
}


.widget-canvas-inner {
    background: var(--lightFrostedBackground);
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
}


.react-grid-item {
    transition: 0.2s ease;
}

.react-grid-item:hover {
    z-index: 100000
}

.react-draggable-dragging {
    transition: 0s ease !important;
}


.react-grid-placeholder {
    z-index: -1 !important;
    background: transparent !important;
    position: relative;
    opacity: 1;
    border: 3px solid rgba(0, 0, 0, 0.066);
    ;
    border-radius: 12px;
    padding: 6px;
    box-sizing: border-box;
    transition: 0.1s ease;
    /* display: none !important; */
}

.react-grid-layout {}

.react-resizable-handle {
    display: none !important;
}

.smart-header {
    display: flex;
    align-items: center;
}

.smart-header>.header-action-btn {
    margin-left: 0px;
    padding-left: 0px;
    position: absolute;
}

.grid-parent {
    position: relative;
    width: 100%;
    height: fit-content;
    padding-top: 0px;
    padding-right: 0px;
    min-height: 1000px;
    padding-left: 15px;
    padding-bottom: 25px;
}

.frosted-grid {
    width: 100%;
    height: -webkit-fill-available;
    position: absolute;
    object-fit: cover;
    /* margin-left: 400px; */
    left: 40px;
    opacity: 0;
    margin-top: 22px;
    transition: 0.2s ease;
    filter: invert();
    z-index: 1;
}

.grid-item {
    transition: all 300ms ease !important
}

.layout {
    margin-top: 0px;
    margin-left: -1px;
    position: relative;
    z-index: 1;
}

.drag-handle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    background-color: transparent;
    position: absolute;
    z-index: 150
}

.drag-pill {
    width: 30px;
    height: 3px;
    background-color: var(--text-gray);
    border-radius: 50px;
    opacity: 0.4;
    transition: 0.2s ease;
}

.drag-handle:hover {
    cursor: move
}

.drag-handle:hover .drag-pill {
    opacity: 1;
    transform: scale(1.1);
}

.canvas-wrapper {
    height: 100%;
    max-height: 170px;
}

.canvas-wrapper>.widget {
    gap: 20px !important;
    max-height: 160px;
}

.canvas-nav {
    display: flex;
    justify-content: center;
    margin-bottom: -5px;
}